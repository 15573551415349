<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput
          id="title"
          description="Título del consejo"
          label="Título del consejo"
          horizontal
          :value="advice.advice_title"
          :disabled="information"
        />
        <CTextarea
          id="description"
          description="Descricpción del consejo"
          label="Descripción del consejo"
          horizontal
          :value="advice.advice_description"
          :disabled="information"
        />
        <CRow>
          <CCol sm="3">
            Patología
          </CCol>
          <CCol sm="3">
            <select
              id="pathologies"
              class="form-select"
              :disabled="information"
            > 
              <option>Elige una patología</option>
              <option
                v-for="action in pathologies"
                :key="action.id_pathology"
                :value="action.id_pathology"
              >
                {{ action.pathology_name }}
              </option>
            </select>
          </CCol>
        </CRow>
      </CForm>
      <div class="d-flex justify-content-center" style="margin-top:20px">
        <CButton
          class="btn btn-secondary" style="margin-right:1em;"
          @click="back()"
        >
          {{backButton}}
        </CButton>
        <CButton
          v-if="!information"
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textButton }}
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert
            color="danger"
            dismissible
            :show.sync="showParametersError"
            close-button
          >
            <strong>AVISO</strong> {{errorText}}

            <CProgress
              :max="10"
              :value="showParametersError"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import {getPathologiesData, getAdvicesData, checkUser} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import $ from "jquery";


export default {
  name: "EditarConsejo",
  data() {
    return {
      advice: {},
      temp: 0,
      tempC: 0,
      pathologies: [],
      id: null,
      title: "Nuevo consejo",
      backButton: 'Volver',
      textButton: "Crear",
      information: false,
      showParametersError: 0,
      errorText: 'Campos vacios o erroneos'
    };
  },
  created() {
    if(!checkUser()) return;
    //Aquí llamamos a los metodos para que se llamen a las funciones y recoger los datos
    //También dependiendo de si uno de los parametros que se pasa por la url, hacemos que sea para editar o de información
    this.id = this.$route.params.id;
    if (this.$route.params.nombre == "infoConsejo") {
      this.information = true;
    } else {
      this.information = false;
    }
    this.getPathologies();
    this.receiveData();

    if(this.id) this.getAdvice();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    receiveData() {
      if (!this.id)
        return;

      this.title = "Editar consejo";
      this.textButton = "Editar";

    },
    back() {
      //el botón para volver hacia atrás
      window.history.back();
    },
    /**
     * Función asociada al boton de editar que dependiendo de si se
     * va a crear o editar un consejo se realizara una u otra acción.
     */
    editButton() {
      let title = document.getElementById("title").value;
      let description = document.getElementById("description").value;
      let idPathology = document.getElementById("pathologies").value;

      if (title == ""){
        this.errorText="El campo Título del consejo esta vacio";
        this.showParametersError = 10;
        return;
      }
      if (description===""){
        this.errorText="El campo Descripción esta vacio";
        this.showParametersError = 10;
        return;
      }
      if (idPathology=="Elige una patología"){
        this.errorText="Seleccione una patología";
        this.showParametersError = 10;
        return;
      }
      
      if (title.length>100){
        this.errorText = "El nombre es demasiado largo.";
        this.showParametersError = 10;
        correct = false;
      }
      if (description.length>400){
        this.errorText = "La descripción es demasiado larga.";
        this.showParametersError = 10;
        correct = false;
      }

      let token = localStorage.token;

      if (!this.id) {
        //Código para crear consejo
        axios.post(
            config.BACK_IP +
              config.BACK_IP_CONSEJO,
            {
              advice_title: title,
              advice_description: description,
              pathology_id: idPathology,
            },
            {
              headers: {
                token: token,
              },
            }
          ).then(() => {
            this.$router.push("/advice");
          }).catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.errorText=error.response.data.message;
                this.showParametersError = 10;
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/pages/404";
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });

        return;
      }
      
      //Código para editar consejo
      axios.patch(
          config.BACK_IP +
            config.BACK_IP_CONSEJO,
          {
            advice_title: title,
            advice_description: description,
            pathology_id: idPathology,
          },
          {
            headers: {
              token: token,
            },
          }
        ).then(() => {
          this.$router.push("/advice");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 400) {
                this.errorText=error.response.data.message;
              this.showFailureParameters();
            }
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/pages/404";
            }
            if (error.response.status == 409) {
                this.errorText = 'Conflicto con duplicidad';
                this.showFailureParameters();
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    /**
     * Función en la que se obtiene y se guarda la información
     * de las patologias en la variable patologías.
     * 
     * @returns {object[]} Un arreglo con las patologias obtenidas
     */
    getPathologies() {
      let data = getPathologiesData(); 
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.pathologies = result.pathologies;
      });
      return this.pathologies;
    },

    showFailureParameters() {
      this.alertCounterParameters = 10;
    },

    getAdvice() {

      let data = getAdvicesData();

      data.then((result) => {
        if (!this.id)
        return;

        for (let index = 0; index < result.advices.length; index++) {
          if(this.id == result.advices[index].advice_id) {
            this.advice = result.advices[index];
            this.advice.advice_title = result.advices[index].advice_title;
            this.advice.advice_description = result.advices[index].advice_description;
            break;
          }
        }

        $("#pathologies").val(this.advice.pathology_id).change();
      });
      this.receiveData();
    }
  },
};
</script>
<style>
textarea.form-control{
  height: 200px;
}
</style>